import {useTranslation} from 'react-i18next'
import {themeConfig} from '../theme'

const BRAND_NAME = themeConfig.BRAND_NAME
const MEMBER_NUMBER = themeConfig.MEMBER_NUMBER

export const RegulatorySupervision = () => {
    const {t} = useTranslation()

    return (
        <div>
            <h1>{t(`LegalPages.RegulatorySupervision.Legal`)}</h1>
            <p>
                {BRAND_NAME} is an authorized member firm of d’Organisme de Surveillance pour Intermédiaires Financiers
                             & Trustees (SO-FIT) (Member No.:{' '}
                <a
                    href="https://www.finma.ch/en/authorisation/self-regulatory-organisations-sros/sro-member-search/"
                    target="_blank"
                    rel="noreferrer"
                >
                    {MEMBER_NUMBER}
                </a>
                             ), and as such is supervised by SO-FIT in its capacity as a supervisory body officially
                             recognized by the Swiss Financial Market Supervisory Authority (FINMA), with supervision
                             pursuant to the Swiss Federal Anti-Money Laundering Act (AMLA). Regulatory issues
                             regarding {BRAND_NAME} should be addressed to SO-FIT.
            </p>
        </div>
    )
}

