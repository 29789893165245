import {useTranslation} from 'react-i18next'
import {themeConfig, contactsURL} from '../theme'
import {Link as RouterLink} from 'react-router-dom'

const BRAND_NAME = themeConfig.BRAND_NAME
const COMPANY_NAME = themeConfig.COMPANY_NAME
const COMPANY_NUMBER = themeConfig.COMPANY_NUMBER
const CONTACT_STREET = themeConfig.CONTACT_STREET
const CONTACT_CITY = themeConfig.CONTACT_CITY
const CONTACT_POSTCODE = themeConfig.CONTACT_POSTCODE
const CONTACT_COUNTRY = themeConfig.CONTACT_COUNTRY
const CONTACT_PHONE = themeConfig.CONTACT_PHONE
const APP_URL = window.location.hostname

export const ComprehensivePrivacyPolicy = () => {
    const {t} = useTranslation()

    return (
        <div>
            <h1 id="comprehensive">{t(`LegalPages.PrivacyPolicy.LegalComprehensive`)}</h1>
            <h4>{t(`LegalPages.PrivacyPolicy.LastUpdated`)}</h4>
            <p>
                This Comprehensive Privacy Notice, together with our <RouterLink to="/legal/terms-of-use">Terms of
                                                                                                    Use</RouterLink> governs {BRAND_NAME}’s
                collection, processing and use of your Personal Data. As
                used in this Privacy Policy, "{BRAND_NAME}", "we", "us" or "our" refers to {COMPANY_NAME}, a Swiss
                E-Money Institution, registered with the Swiss Companies Register under company
                number {COMPANY_NUMBER}, with its registered address
                at {CONTACT_STREET}, {CONTACT_POSTCODE} {CONTACT_CITY}, {CONTACT_COUNTRY}.
            </p>
            <p>
                References to “you” or “your” in this privacy notice are to the individual who is accessing or applying
                to use the {BRAND_NAME} Services (as defined below) either on your own account
                or on behalf of a business. This includes, in relation to a customer or prospective customer
                of {BRAND_NAME}, any sole trader and any principals, including the managing and financial
                directors, any other directors and officers, shareholders, partners and beneficial owners of a customer,
                as well as any member of staff accessing or using the {BRAND_NAME} Services on
                behalf of a customer.
            </p>
            <p>
                This notice shows our commitment to protect your privacy rights and sets out the basis on which any
                personal information we collect from you, or that you provide to us, will be
                processed by us. It applies to:
            </p>
            <div className="dottedList">
                <p>the website/portal features and services provided to you when you visit {BRAND_NAME}’s website</p>
                <p>your use of software including terminals, mobile and desktop applications provided by {BRAND_NAME};
                   and</p>
                <p>email, other electronic messages including SMS, telephone, web chat, website/portal and other
                   communications between you and {BRAND_NAME}.</p>
            </div>
            <p>Together these are all referred to in this notice as “{BRAND_NAME} Services”.</p>
            <p>Please read the following carefully to understand our views and practices regarding your Personal Data
               and how we will treat it.</p>
            <p>
                {BRAND_NAME} is the data controller under this Privacy Policy, which sets out the manner in
                             which {BRAND_NAME}, as data controller, may use your Personal Data.
            </p>
            <p>
                “DPL” herein means data protection law applicable to {BRAND_NAME}, including the{' '}
                <a
                    href="https://gdpr-info.eu/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Swiss Data Protection Act
                </a>{' '}
                and the EU General Data Protection Regulation 2016/679, their successors or implementing texts. Personal
                Data" has the meaning set forth in DPL. This notice covers our use of your
                Personal Data arising from use of the {BRAND_NAME} website ({APP_URL}) as well as registering /
                subscribing / buying / selling / using our products and services. If you have any
                questions or need any further clarity please get in touch. Contact details are set out below in the <a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a> section.
            </p>
            <p>In this Privacy Policy capitalised terms have the meaning set out in the Definitions section below.</p>
            <p>{BRAND_NAME} is committed to protecting and respecting your privacy. The purpose of this Privacy Policy
                            is to describe:</p>
            <div className="dottedList">
                <p>The types of Personal Data we collect and how it may be used;</p>
                <p>Our use of cookies and similar technology;</p>
                <p>How and why we may disclose your Personal Data to third parties;</p>
                <p>The transfer of your Personal Data within and outside of Switzerland and the European Economic Area
                   (“EEA”);</p>
                <p>Your statutory rights concerning your Personal Data;</p>
                <p>The security measures we use to protect and prevent the loss, misuse, or alteration of Personal Data;
                   and</p>
                <p>Retention of your Personal Data by {BRAND_NAME}.</p>
            </div>
            <h3>Collection and use of personal information</h3>
            <span className="paragraphSection">A. Personal Information We Collected</span>
            <p>
                We collect Personal Data to provide you with our Services. When we require certain personal information
                from users it is because we are required by law to collect this information or
                it is relevant for specified purposes. Any information you provide to us that is not required is
                voluntary. You are free to choose whether to provide us with the types of personal
                information requested, but we may not be able to serve you as effectively or offer you all of our
                Services when you do choose not to share certain information with us.
            </p>
            <p>
                For example, we collect personal information which is required under the law to register for an account,
                add a payment method, or execute a transaction. We also collect personal
                information when you use or request information about our Services, subscribe to marketing
                communications, request support, complete surveys or sign up for a {BRAND_NAME} event. We may
                also collect personal information from you offline, such as when you attend one of our events, or when
                you contact customer support. We may use this information in combination with
                other information we collect about you as set forth in this Privacy Policy. This may include:
            </p>
            <div className="dottedList">
                <p>Personal Identification Information: Full name, date of birth, age, nationality, gender, signature,
                   utility bills, photographs, phone number, home address, and/or email.</p>
                <p>Account information, such as username, password, account settings and preferences;</p>
                <p>Financial information, such as bank account numbers, bank statement, and trading information;</p>
                <p>
                    Formal Identification Information used for identity verification: Tax ID number, passport number,
                    driver’s license details, national identity card details, photograph
                    identification cards, and/or visa information.
                </p>
                <p>Residence verification information, such as utility bill details or similar information;</p>
                <p>Information regarding the way in which you use our services, such as when you used our services and
                   the specific services used; and</p>
                <p>Information relating to communications with us, whether through the {BRAND_NAME} website or via
                   e-mail, over the phone or via any other medium.</p>
                <p>Employment Information: Office location, job title, and/or description of role.</p>
                <p>Transaction Information: Information about the transactions you make on our Services, such as the
                   name of the recipient, your name, the amount, and/or timestamp.</p>
                <p>Online Identifiers: Geo location/tracking details, browser fingerprint, OS, browser name and version,
                   and/or personal IP addresses.</p>
                <p>Collection and Use of Face Data: We collect and use face data, including facial images and biometric
                   identifiers, solely for user verification purposes to enhance platform security, prevent fraud, and
                   comply with legal requirements. Your consent to this data collection is implicit when using our
                   services.</p>
                <p>
                    <div>
                        Usage Data: Survey responses, information provided to our support team, public social networking
                        posts, authentication data, security questions, user ID, click-stream data and
                        other data collected via cookies and similar technologies. Please read
                        our <RouterLink to="/legal/cookie-statement">Cookie Statement</RouterLink> for more information.
                    </div>
                </p>
                <p>Any other information that you or your customer provide to us.</p>
            </div>
            <p>
                We also automatically collect certain computer, device and browsing information when you access
                the {BRAND_NAME} website or use {BRAND_NAME} Services. This information is aggregated to
                provide statistical data about our users' browsing actions and patterns, and does not personally
                identify individuals. This information may include:
            </p>
            <div className="dottedList">
                <p>
                    Computer or mobile device information, including IP address, operating system, network system,
                    browser type and settings. This information may be directly obtained by {BRAND_NAME}{' '}
                    or through third party services such as Google Analytics and other tracking tools. This service
                    usage data helps us our systems to ensure that our interface is accessible for users
                    across all platforms and can aid during criminal investigations;
                </p>
                <p>Website usage information.</p>
            </div>
            <p>
                We also collect information through our applications. if you download or use mobile or desk top
                applications provided by {BRAND_NAME}, we may receive information about your location
                and your device or the service you are using (including where a payment transaction takes place). Some
                devices allow applications to access real-time location-based information (for
                example GPS). Our mobile and desktop apps may collect such information from your mobile device or your
                computer at any time while you download or use our apps, if your device has
                real-time location data access enabled. Where required, we will always seek to notify you if it is our
                intent to collect real-time location information and, also where required by law,
                obtain your consent. We may use such information for regulatory purposes, our own due diligence checks,
                to better understand transaction patterns and to optimise your experience.
            </p>
            <p>
                We may receive information about you from other sources and add it to our account information. For
                example, we work closely with, and receive information from, third parties like
                business partners, financial institutions, merchants, subcontractors in technical, payment and delivery
                services, advertising networks, analytics providers, search information
                providers, credit reference and fraud prevention agencies. Credit reference and fraud prevention agency
                use is further explained below. We may also review public comments and opinions
                made on social networking sites (e.g. Facebook and Twitter) to better understand our customers and our
                provision and development of the {BRAND_NAME} Services.
            </p>
            <p>Finally, we may collect Personal Data from third party partners and public sources, which include:</p>
            <div className="dottedList">
                <p>Reputational information;</p>
                <p>Financial information;</p>
                <p>Business activities of corporate customers.</p>
            </div>
            <p>
                We need to collect certain types of information for compliance with legal requirements relating to our
                anti-fraud/anti-money laundering/counter financing of terrorism/know your
                customer obligations. If this information is not provided we will not be able to provide a service for
                you. Your Personal Data may also be processed if it is necessary on reasonable
                request by a law enforcement or regulatory authority, body or agency or in the defense of legal claims.
                We will not delete Personal Data if relevant to an investigation or a dispute.
                It will continue to be stored until those issues are fully resolved. We may perform behavioral tracking
                of a customer's activities on our {BRAND_NAME} website or across different
                websites, or allow third-party data collection through our {BRAND_NAME} Service.
            </p>
            <p>
                It is important to note that the Personal Data we collect on you when you create an account will be
                retained for the mandatory retention period set forth by applicable law and as
                necessary for us to maintain an exhaustive documentation of our operations as required from us as
                regulated financial sector professionals, even if your account has not been
                successfully activated (e.g., if account verification has not been completed) or no transaction has been
                made using it.
            </p>
            <p>
                Our privacy notice has incorporated elements from the{' '}
                <a
                    href="https://eur-lex.europa.eu/legal-content/en/TXT/?uri%3DCELEX%253A32016R0679"
                    target="_blank"
                    rel="noreferrer"
                >
                    General Data Protection Regulation (GDPR)
                </a>{' '}
                as we act in accordance to its personal information processing rules within the European Economic Area
                (EEA). For individuals who reside in the European Economic Area, (including
                Switzerland (collectively “EEA Residents”), pursuant to Article 6 of the EU General Data Protection
                Regulation (GDPR) or any equivalent legislation (collectively “EEA Data Protection
                Law”), we process this personal information to comply with our legal obligations.
            </p>
            <span className="paragraphSection">B. Use of Cookies and Similar Technology</span>
            <p>
                {BRAND_NAME} receives and stores certain information automatically whenever you interact
                             with {BRAND_NAME}. The {BRAND_NAME} website uses cookies. Cookies are small text files that
                             are
                             placed on your computer by websites that you visit, including {BRAND_NAME}. They are widely
                             used in order to make websites work, or work more efficiently, as well as to provide
                             information to the owners of the site.
            </p>
            <p>
                Cookies are typically stored on your computer's hard drive. Information collected from cookies is used
                by us to evaluate the effectiveness of our website, analyze trends, and
                administer the Platform. The information collected from cookies allows us to determine such things as
                which parts of our website are most visited and difficulties our visitors may
                experience in accessing our website. With this knowledge, we can improve the quality of your experience
                on the Platform by recognizing and delivering more of the most desired features
                and information, as well as by resolving access difficulties. We also use cookies and/or a technology
                known as web bugs or clear gifs, which are typically stored in emails to help us
                confirm your receipt of, and response to, our emails and to provide you with a more personalized
                experience when using our website. The information we collect includes:
            </p>
            <div className="dottedList">
                <p>
                    Technical information, including the Internet protocol (IP) address used to connect your computer or
                    device to the Internet, your login information, browser type and version, time
                    zone setting, browser plug-in types and versions, operating system platform;
                </p>
                <p>
                    Information about your visit or whether you opened an email, including the full Uniform Resource
                    Locators (URL) clickstream to, through and from our site (including date and time);
                    products or services you viewed or searched for; page response times, download errors, length of
                    visits to certain pages, page interaction information (such as scrolling, clicks
                    and mouse-overs) and methods used to browse away from the site page and any phone number used to
                    call our customer service number.
                </p>
            </div>
            <p>
                We use third party service provider(s), to assist us in better understanding the use of our website. Our
                service provider(s) will place cookies on the hard drive of your computer and
                will receive information that we select that will educate us on such things as how visitors navigate
                around our site, what products are browsed, and general Transaction information.
                Our service provider(s) analyses this information and provides us with aggregate reports. The
                information and analysis provided by our service provider(s) will be used to assist us in
                better understanding our visitors' interests in our website and how to better serve those interests. The
                information collected by our service provider(s) may be linked to and combined
                with information that we collect about you while you are using the Platform. Our service provider(s)
                is/are contractually restricted from using information they receive from our
                website other than to assist us.
            </p>
            <p>
                If you want to avoid using cookies altogether, you can disable cookies in your browser. However,
                disabling cookies might make it impossible for you to use certain features or our
                website or services, such as logging on to your Account or making transactions. Your use of our website
                or service with a browser that is configure to accept cookies constitutes an
                acceptance of our and third-party cookies.
            </p>
            <p>
                For more information about cookies, the types of cookies we use and how we use them please see
                our <RouterLink to="/legal/cookie-statement">Cookie Statement</RouterLink>.
            </p>
            <span className="paragraphSection">C. How We Use Your Personal Information</span>
            <p>
                We collect and use your information for a variety of reasons. We need some information to enter into and
                perform our contract – for example your contact and payment details. Some
                information processing is required by law due to our anti-fraud screening obligations or in the public
                interest such as making sure we verify our customers' identities.
            </p>
            <p>
                Some information is processed because you've given your consent to that, which can be withdrawn in your
                account preferences and settings. Other information we collect and use because
                we have legitimate business interests to so, having taken into account your rights, interests and
                freedoms.
            </p>
            <p>We may use your Personal Information to:</p>
            <div className="dottedList">
                <p>
                    Create and administer your {BRAND_NAME} account and generally for accounting, billing, maintenance
                    of legal documentation and claim and dispute management. Related processing
                    operations are necessary for the performance of a contract with you (or to take steps at your
                    request prior to entering into a contract), and for compliance with legal obligations
                    to which we are subject;
                </p>
                <p>
                    Process your {BRAND_NAME} transactions. Related processing operations are necessary for the
                    performance of a contract with you and for compliance with legal obligations to which we
                    are subject;
                </p>
                <p>
                    <div>
                        Verify your identity in accordance with applicable know-your-customer, money laundering and
                        other financial sector legislation or regulations, including as required for
                        compliance with the <RouterLink to="/legal/aml-ctf-policy">{BRAND_NAME} Anti Money Laundering
                                                                                           ("AML")/Counter Terrorist
                                                                                           Financing ("CTF")
                                                                                           Policy</RouterLink>, as well
                        as address
                        other law enforcement needs as more fully described in our <RouterLink to="/legal/terms-of-use">Terms
                                                                                                                  of
                                                                                                                  Use</RouterLink>,
                        and generally as required for compliance with legislation and
                        regulations applicable to {BRAND_NAME}. {BRAND_NAME} utilises and thus shares information with a
                        third-party AML verification tool in addition to its internal identification
                        screening procedures.
                    </div>
                </p>
                <p>Personalise your {BRAND_NAME} Services experience. Related processing operations are necessary for
                   purposes of our legitimate interests (that is, improving our services);</p>
                <p>
                    Analyse {BRAND_NAME} website usage, and improve our website and website offerings. Related
                    processing operations are necessary for purposes of our legitimate interests (that is,
                    improving and promoting our services);
                </p>
                <p>
                    Help us respond to your customer service requests and support needs. Related processing operations
                    are necessary for the performance of a contract with you, and for purposes of our
                    legitimate interests (that is, improving our services and offering you the best experience);
                </p>
                <p>
                    Contact you about {BRAND_NAME} Services. The email address you provide may be used to communicate
                    information and updates related to your use of the {BRAND_NAME} Services.
                </p>
            </div>
            <span className="paragraphSection">Automated Decisions Making</span>
            <p>
                Automated decisions mean that a decision concerning you is made automatically on the basis of a computer
                determination (using software algorithms), without our human review. We may
                make automated decisions on certain matters. For example, we may do this to decide whether we can
                provide our services to you based on a credit check/risk profiling. Depending on the
                outcome of the credit check/risk profiling, a decision will be reached automatically as to whether we
                are able to provide products or services to you based on your credit worthiness.
            </p>
            <p>If you disagree with the decision you are entitled to contest this by contacting us at following email
               address: dpo@{APP_URL}</p>
            <span className="paragraphSection">D. Marketing</span>
            <p>
                We may also occasionally communicate company news, updates, promotions and related information relating
                to similar products and services provided by {BRAND_NAME}. We may also
                administer a contest, promotion, survey or other site features as will be more explained on the website.
                We shall only do this where you have given us your consent or otherwise where
                we are permitted to do so under the DPL in pursuit of our legitimate interests (that is, promoting our
                services).
            </p>
            <p>We may share Personal Data with third parties to help us with our marketing and promotional projects, or
               sending marketing communications.</p>
            <p>
                If you want to opt out of receiving promotional and marketing emails, text messages, post and other
                forms of communications from us (or our promotional partners) in relation to which
                you might receive in accordance with this section, you can best opt out by using one of the following
                ways:
            </p>
            <div className="dottedList">
                <p>Log into your account and update your profile.</p>
                <p>Click "unsubscribe" at the bottom of an email we sent you.</p>
                <p>contact us at dpo@{APP_URL} to opt-out.</p>
            </div>
            <p>
                If you do opt out of receiving promotional and marketing messages, we can still contact you regarding
                our business relationship with you, such as account status and activity updates,
                survey requests in respect of products and services we have provided to you after you reserve from us,
                reservation confirmations or respond to your inquiries or complaints, and similar
                communications.
            </p>
            <h3>DISCLOSING AND TRANSFERRING PERSONAL DATA</h3>
            <p>We may disclose your Personal Data to third parties and legal and regulatory authorities, and transfer
               your Personal Data outside Switzerland and the EEA, as described below.</p>
            <span className="paragraphSection">A. Disclosures to Third Parties</span>
            <p>There are certain circumstances where we may transfer your Personal Data to employees, contractors and to
               other parties.</p>

            <div className="dottedList">
                <p>
                    <div>
                        We may share information about you with other members of our group of companies so we can
                        provide the best service across our group. They are bound to keep your information in
                        accordance with this Privacy Policy;
                    </div>
                </p>
                <p>
                    We may also share your information with certain contractors or service providers. They may process
                    your Personal Data for us, for example, if we use a marketing agency. Other
                    recipients/service providers include advertising agencies, IT specialists, database providers,
                    backup and disaster recovery specialists, email providers or outsourced call centres.
                    Our suppliers and service providers will be required to meet our standards on processing information
                    and security. The information we provide them, including your information, will
                    only be provided in connection with the performance of their function;
                </p>
                <p>
                    We may also share your information with certain other third parties. We will do this either when we
                    receive your consent or because we need them to see your information to provide
                    products or services to you. These include credit reference agencies, anti-fraud databases,
                    screening agencies and other partners we do business with.
                </p>
                <p>
                    We may share your information to improve and develop our business, including without limitation to
                    optimise our websites/portals, products and services. This may include using
                    information you insert into forms but do not submit to us, for example by using that information to
                    optimise our website(s) and contacting you for customer services purposes in
                    relation to that form. We can also use your personal information to develop and test new products
                    and services.
                </p>
            </div>

            <p>Your Personal Data may be transferred to other third party organisations in certain scenarios:</p>

            <div className="dottedList">
                <p>
                    If we're discussing selling or transferring part or all of our business – the information may be
                    transferred to prospective purchasers under suitable terms as to confidentiality;
                </p>
                <p>If we are reorganised or sold, information may be transferred to a buyer who can continue to provide
                   services to you;</p>
                <p>If we're required to by law, or under any regulatory code or practice we follow, or if we are asked
                   by any public or regulatory authority – for example the Police;</p>
                <p>If we are defending a legal claim your information may be transferred as required in connection with
                   defending such claim.</p>
                <p>To comply with local and national laws;</p>
                <p>
                    To send marketing messages, to provide you with the information on products and services you have
                    requested or we think may be of interest to you; to obtain your views on our
                    goods, services and our website(s); in respect of marketing, market research and similar activities,
                    we may use your personal information for such purposes whether or not you are
                    accepted as or continue to receive {BRAND_NAME} Services. If you no longer wish to receive marketing
                    or promotional information from {BRAND_NAME}, you can always stop it;
                </p>
                <p>
                    to manage and mitigate our credit risks. if you apply for one of our financial products we will
                    assess your financial position (and / or of your business), to the extent this is
                    provided for in the applicable Terms of Use. This credit check will also affect any linked parties
                    such as directors, shareholders and principals. We can do so by receiving and
                    sharing information from and with credit reference agencies and fraud prevention agencies. This
                    credit check will affect also anyone with whom you have a joint account or similar
                    financial association. If it is a joint application, and such link does not already exist, then one
                    may be created. These links will remain until you file a “notice of
                    disassociation” at the credit reference agencies. For your information, the agencies will record our
                    enquiries which may be seen by other companies who make their own credit
                    enquiries; and a “footprint” may be placed on your credit file, whether or not you are accepted as
                    our customer. If you are a director, we will seek confirmation from credit
                    reference agencies that the residential address that you provide is the same as that held by the
                    relevant companies’ registry (where applicable). In addition, where you take
                    services from us, we will give information on how you manage your accounts to the credit reference
                    agencies. If you do not repay any monies in full and on time, credit reference
                    agencies will record the outstanding debt and may share this information with other organization
                    that perform checks similar to ours. Records remain on file at such agencies for 6
                    years after they are closed, whether settled by you or defaulted. If you would like further
                    information on our use of credit reference agencies, please contact us;
                </p>
                <p>
                    To prevent, detect and prosecute fraud or crime, {BRAND_NAME} may participate in anti-fraud
                    initiatives, which involve assessing you (and/or your customers) and monitoring your
                    transactions and/or locations, to detect patterns requiring investigations or otherwise profile and
                    assess the likelihood of fraud occurring. We can do so utilizing products and
                    services from third parties. Besides, if you give us false or inaccurate information about you, or
                    we identify or suspect a fraud or a crime, we may pass your information to fraud
                    prevention agencies and to law enforcement agencies, and we may decide to take legal action against
                    you;
                </p>
                <p>To prevent or mitigate information security risk;</p>
                <p>
                    To manage and enforce our rights, terms of use or any other contracts with you (and/or your
                    business), including to manage any circumstances where transactions, rewards or points
                    are disputed; manage, investigate and resolve complaints; or recover debt or in relation to your
                    insolvency;
                </p>
            </div>
            <p>Your Personal Data may be shared if it is made anonymous and aggregated, as in such circumstances the
               information will cease to be Personal Data.</p>
            <p>Your information will not be sold, exchanged, or shared with any third parties without your consent,
               except to provide {BRAND_NAME} Services or as required by law.</p>
            <p>
                The third-party service providers of {BRAND_NAME} are contractually bound to protect and use such
                information only for the purposes for which it was disclosed, except as otherwise
                required or permitted by law. We ensure that such third parties will be bound by terms complying with
                DPL.
            </p>
            <p>Specifically, Your Personal Data may be transferred to the following third party organisations in certain
               scenarios:</p>
            <p>
                GlobalPass analyses ID documents, and other AML-KYC related documents and information to assist us in
                satisfying our KYC obligations. Globalpass processes and stores data in ISO
                27001-certified datacentres worldwide. Learn more about how{' '}
                <a
                    href="https://globalpass.ch/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                >
                    GlobalPass processes data
                </a>
                .
            </p>
            <p>
                When you top-up through the Platform by wire transfer or credit card, your Personal Data is shared with
                Bitnuk AG, where Quantum Coins are purchased. Bitnuk AG processes and stores
                data in ISO 27001-certified datacentres worldwide.{' '}
                <a
                    href="https://bitnuk.com/legal#privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                >
                    Learn more about how Bitnuk processes data
                </a>
                .
            </p>
            <p>
                When you hold, buy, sell or transfer cryptocurrency through the Platform, your Personal Data is shared
                with Bitnuk AG. Bitnuk AG processes and stores data in ISO 27001-certified
                datacentres worldwide.{' '}
                <a
                    href="https://bitnuk.com/legal#privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                >
                    Learn more about how Bitnuk processes data
                </a>
                .
            </p>
            <p>
                When you hold, buy, sell or transfer cryptocurrency through the Platform, your Personal Data is shared
                with Bank Frick. Bank Frick processes and stores data in ISO 27001-certified
                datacentres worldwide.{' '}
                <a
                    href="https://www.bankfrick.li/en/contact/legal-information"
                    target="_blank"
                    rel="noreferrer"
                >
                    Learn more about how Bank Frick processes data
                </a>
                .
            </p>
            <p>
                When you apply for a debit card through the Platform, Your Personal Data is shared with UAB Creditco for
                the approval process. UAB Creditco is an official Lithuanian representative of
                Wirecard Card Solutions Limited. UAB Creditco processes and stores data in ISO 27001-certified
                datacentres worldwide.{' '}
                <a
                    href="https://uabcreditco.lt/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Learn more about how UAB Creditco processes data
                </a>
                .
            </p>
            <p>
                When you apply for an IBAN payment account through the Platform, Your Personal KYC Data is shared with
                the relevant bank/payment service provider, depending on which jurisdiction you
                choose for the approval process. Some of the banks/paymnet service providers we work with process and
                store your data as follows:
            </p>
            <div className="dottedList">
                <p>
                    <div>
                        UAB Verified Payments (Lithuania) processes and stores data in ISO 27001-certified datacentres
                        worldwide.{' '}
                        <a
                            href="https://verifo.com/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more about how UAB Verified Payments processes data
                        </a>
                        .
                    </div>
                </p>
                <p>
                    <div>
                        Bank Frick (Liechtenstein) processes and stores data in ISO 27001-certified datacentres
                        worldwide.{' '}
                        <a
                            href="https://www.bankfrick.li/en/contact/legal-information"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more about how Bank Frick processes data
                        </a>
                        .
                    </div>
                </p>
                <p>
                    <div>
                        PayrNet is a wholly owned subsidiary of Railsbank Technology Limited (United Kingdom). Railsbank
                        processes and stores data in ISO 27001-certified datacentres worldwide.{' '}
                        <a
                            href="https://www.railsbank.com/privacypolicy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more about how Railsbank processes data
                        </a>
                        .
                    </div>
                </p>
                <ul>
                    <div className="dottedList">
                        <p>
                            <div>
                                Onpex S.A. (Luxembourg) processes and stores data in ISO 27001-certified datacentres
                                worldwide.{' '}
                                <a
                                    href="https://www.onpex.com/privacy-notice"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Learn more about how Onpex processes data
                                </a>
                                .
                            </div>
                        </p>
                        <p>
                            <div>
                                UAB Phonix Payments (Lithuania), which operates the Ibanera payment platform, processes
                                and stores data in ISO 27001-certified datacentres worldwide.{' '}
                                <a
                                    href="https://ibanera.com/privacy-policy/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Learn more about how UAB Phonix Payments processes data
                                </a>
                                .
                            </div>
                        </p>
                        <p>
                            <div>
                                LHV Bank (Estonia) processes and stores data in ISO 27001-certified datacentres
                                worldwide.{' '}
                                <a
                                    href="https://www.lhv.ee/en/principles-of-processing-customer-data"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Learn more about how LHV Bank processes data
                                </a>
                                .
                            </div>
                        </p>
                    </div>
                </ul>
            </div>
            <span className="paragraphSection">B. Disclosures to Legal Authorities</span>
            <p>
                {BRAND_NAME} may be required by law to pass information about you to regulatory authorities and law
                             enforcement bodies worldwide, or we may otherwise determine that it is appropriate
                             or necessary to do so. Such disclosures may also include requests from governmental or
                             public authorities, or with commercial organizations with whom you may have had dealings
                             and whom
                             are seeking to mitigate fraud risk, or for the purposes of litigation or legal process,
                             national security or where we deem it in the national or public interest or otherwise
                             lawful to
                             do so. As such, we may share your Personal Data with law enforcement, data protection
                             authorities, government officials, and other authorities when:
            </p>
            <div className="dottedList">
                <p>Compelled by court order, or other legal procedure.</p>
                <p>Disclosure is necessary to report suspected illegal activity.</p>
                <p>
                    Disclosure is necessary to investigate violations of this Privacy Policy or our&nbsp;
                    <RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink>.
                </p>
            </div>
            <span className="paragraphSection">C. International Transfers of Personal Data</span>
            <p>
                We store and process your Personal Data in data centers around the world,
                wherever {BRAND_NAME} facilities or service providers are located. As such, we may transfer your
                Personal Data
                outside of Switzerland and the European Union. Some of the countries to which your Personal DatHYPERLINK
                "
                <a
                    href="https://www.aki.ee/en"
                    target="_blank"
                    rel="noreferrer"
                >
                    https://www.aki.ee/en
                </a>
                "ses that are located outside the EU do not benefit from an adequacy decision issued by the EU
                Commission regarding protection afforded to Personal Data in that country. Details of
                these specific countries can be found here:{' '}
                <a
                    href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"
                    target="_blank"
                    rel="noreferrer"
                >
                    https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en
                </a>
                . Such transfers are undertaken in accordance with our legal and regulatory obligations and appropriate
                safeguards under DPL will be implemented, such as standard data protection
                clauses with data recipients or processors approved by competent authorities. A copy may be requested at
                the address set out in the&nbsp;
                <a
                    href={contactsURL}
                    target="_blank"
                    rel="noreferrer"
                >Contact Us</a>&nbsp;section.
            </p>
            <h3>YOUR STATUTORY RIGHTS</h3>
            <p>You have many rights concerning your Personal Data under DPL as mentioned below, and can exercise them by
               emailing us at dpo@{APP_URL}</p>
            <p>
                Access: you are entitled to ask us if we are processing your information and, if we are, you can request
                access to your Personal Data. This enables you to receive a copy of the
                Personal Data we hold about you and certain other information about it to check that we are lawfully
                processing it. We process a large quantity of information, and can thus request, in
                accordance with the DPL, that before the information is delivered, you specify the information or
                processing activities to which your request relates.
            </p>
            <p>Correction: you are entitled to request that any incomplete or inaccurate Personal Data we hold about you
               is corrected.</p>
            <p>
                Erasure: you are entitled to ask us to delete or remove Personal Data in certain circumstances. There
                are also certain exceptions where we may refuse a request for erasure, for
                example, where the Personal Data is required for compliance with law or in connection with claims.
            </p>
            <p>
                Restriction: you are entitled to ask us to suspend the processing of certain of your Personal Data about
                you, for example if you want us to establish its accuracy or the reason for
                processing it.
            </p>
            <p>Transfer: you may request the transfer of certain of your Personal Data to another party.</p>
            <p>
                Objection: where we are processing your Personal Data based on a legitimate interests (or those of a
                third party) you may challenge this. However we may be entitled to continue
                processing your information based on our legitimate interests or where this is relevant to legal claims.
                You also have the right to object where we are processing your Personal Data
                for direct marketing purposes.
            </p>
            <p>Automated decisions: you may contest any automated decision made about you where this has a legal or
               similar significant effect and ask for it to be reconsidered.</p>
            <p>
                Please consider that, depending on the country from where you use the {BRAND_NAME} Services, not all the
                above rights may be available to you. Also, there might be cases where these
                rights cannot be enforced: for example, you cannot object to us using your information when it is
                required by the law, or to manage a complaint; similarly you cannot ask us to delete
                your information if you want to continue using the {BRAND_NAME} Services.
            </p>
            <h3>SECURITY OF PERSONAL DATA</h3>
            <p>
                We use a variety of security measures to ensure the confidentiality of your Personal Data, and to
                protect your Personal Data from loss, theft, unauthorised access, misuse, alteration
                or destruction. These security measures include, but are not limited to:
            </p>

            <div className="dottedList">
                <p>Password protected directories and databases;</p>
                <p>Secure Sockets Layered (SSL) technology to ensure that your information is fully encrypted and sent
                   across the Internet securely;</p>
                <p>PCI Scanning to actively protect our servers from hackers and other vulnerabilities.</p>
            </div>

            <p>
                All financially sensitive and/or credit information is transmitted via SSL technology and encrypted in
                our database. Only authorised {BRAND_NAME} personnel are permitted access to your
                Personal Data, and these personnel are required to treat the information as highly confidential. The
                security measures will be reviewed regularly in light of new and relevant legal and
                technical developments.
            </p>
            <p>
                We do not ask for financial or payment information, such as your credit card number, passcode, account
                number or pin number, in an e-mail, text or any other communication that we send
                to you. Please always check that any website on which you are asked for financial or payment information
                in relation to our reservations or services is operated by {BRAND_NAME}. If you
                do receive a suspicious request, do not provide your information and report it by contacting one our
                member service representatives as set in this Privacy Policy.
            </p>
            <p>
                You are responsible for keeping your account passcode, membership numbers and pin numbers safe and
                secure. Do not share those with anyone. If there is an unauthorised use or any other
                breach of security involving your information, you must notify us below as soon as possible.
            </p>
            <p>
                Please note, the transmission of information via the internet is not completely secure. Although we will
                do our best to protect your personal information, we cannot guarantee the
                security of your information transmitted to our site, unless you are communicating with us through a
                secure channel that we have provided. Once we have received your information, we
                will use strict procedures and security features to try to prevent unauthorized access.
            </p>
            <h3>RETENTION OF PERSONAL DATA</h3>
            <p>
                We retain Personal Data for as long as necessary to fulfill purposes described in this Privacy Policy,
                subject to our own legal and regulatory obligations. The criteria we may use to
                determine the retention period for certain categories of data includes:
            </p>
            <div className="dottedList">
                <p>How long you have been a {BRAND_NAME} member;</p>
                <p>whether there are contractual or legal obligations that exist that require us to retain the data for
                   a certain period of time;</p>
                <p>whether there is any ongoing legal or financial claim that relates to your relationship with us;</p>
                <p>whether any applicable law, statute, or regulation allows for a specific retention period; and</p>
                <p>what the expectation for retention was at the time the data was provided to us.</p>
            </div>
            <p>
                While you use the {BRAND_NAME} Services, we will retain your personal information as long as necessary
                to provide you with the services of your choice. That would generally mean we
                retain your personal information as long as you are our customer and for a period of time afterwards.
                When our relationship with you ends, we still need to retain certain of your
                personal information for a time that depends on the legal and regulatory requirements of the country
                where you are located. For example, we will retain your information for the time
                allowed by the local laws to start a legal claim (so called “statute of limitation”), or for as long as
                we are ordered pursuant to on an order from the courts, or by law enforcement
                agencies or our regulators. In accordance with our record keeping obligations, we will retain Account
                and other Personal Data for at least five years (and some up to ten years, as
                required by applicable law) after an Account is closed.
            </p>
            <h3>UPDATES TO THIS PRIVACY POLICY</h3>
            <p>
                This Privacy Policy was last revised effective as of the notice at the beginning of this policy page. We
                may change this Privacy Policy from time to time, so it is advisable to review
                it frequently. Changes to this Privacy Policy will be announced on our website or through similar means
                for a reasonable length of time prior to and following the change taking effect.
            </p>
            <h3>Definitions</h3>
            <p>
                Account. The contractual arrangement wherein a {BRAND_NAME} member has accepted
                our <RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink> and Privacy Policy, and received approval
                to use
                the {BRAND_NAME} Services, including the purchase and sale of Bitcoins and to perform associated
                Transactions;
            </p>
            <p>
                {BRAND_NAME} Platform. Hardware and software technologies used by {BRAND_NAME} to provide
                             the {BRAND_NAME} Service as set out in our&nbsp;
                <RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink>;
            </p>
            <p>
                DPL means data protection law applicable to {BRAND_NAME}, including the{' '}
                <a
                    href="https://www.fedlex.admin.ch/eli/cc/1993/1945_1945_1945/en"
                    target="_blank"
                    rel="noreferrer"
                >
                    Swiss Data Protection Act
                </a>{' '}
                and the EU General Data Protection Regulation 2016/679, their successors or implementing texts as well
                as equivalent legislation, which is applied to the processing of Personal Data by{' '}
                {BRAND_NAME}.
            </p>
            <p>
                Personal Data. Information that identifies an individual, such as name, address, e-mail address, trading
                information, and banking details. Personal Data does not include anonymised
                and/or aggregated data that does not identify a specific user;
            </p>
            <p>
                Service(s). The technological platform, functional rules and market managed by {BRAND_NAME} to permit
                Sellers and Buyers to perform purchase and sale transactions of Bitcoins and/or
                other Cryptocurrencies.
            </p>
            <p>Transaction. Includes the following:</p>
            <div className="dottedList">
                <p>The action between the Member to Buy and/or Sell cryptocurrencies through the Service for currencies
                   at the market rate.</p>
                <p>The transfer of cryptocurrencies between Members ("Transfer Transaction");</p>
                <p>The transfer of currencies among Members ("Currency Transfer Transaction"); and</p>
                <p>{BRAND_NAME} may not offer all these transaction types at this time.</p>
            </div>
            <h3>Global Notice</h3>
            <p>
                This notice is global in scope, but is not intended to override any legal rights or prohibitions in any
                territory where such rights or prohibitions prevail. In such event, the rights
                and obligations set out in this notice will apply, subject only to amendment under any applicable local
                law having precedence.
            </p>
            <h3>Contact Us</h3>
            <p>
                If you have questions or concerns regarding this Privacy Policy, or if you have a complaint, please
                contact us via the contact form on the{' '}
                <a
                    href={contactsURL}
                    target="_blank"
                    rel="noreferrer"
                >Contact Us</a> page of our website, or by writing to us at the following address:
            </p>

            <p>{COMPANY_NAME}</p>
            <p>Attn: Data Protection Officer</p>
            <p>{CONTACT_STREET}</p>
            <p>
                {CONTACT_POSTCODE} {CONTACT_CITY}
            </p>
            <p>{CONTACT_COUNTRY}</p>
            <p>{CONTACT_PHONE}</p>
            <h3>Complaints</h3>

            <p>
                You can also complain about our processing of your personal information to the relevant data protection
                authority. You can complain in the EU member state where you live or work, or in
                the place where the alleged breach of data protection law has taken place. In Switzerland,{' '}
                <a
                    href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    The Federal Data Protection and Information Commissioner (FDPIC)
                </a>{' '}
                is the competent authority for data processing by federal bodies and private persons, including
                enterprises. As far as data processing in Europe, the competent body is the{' '}
                <a
                    href="https://edps.europa.eu/data-protection_en"
                    target="_blank"
                    rel="noreferrer"
                >
                    European Data protection Supervisor
                </a>
                . As far as data processing by cantonal or communal authorities is concerned, the supervision is up to
                the cantonal and communal data protection commissioners. You can find all contact
                information here.
            </p>
        </div>
    )
}
