import {useTranslation} from 'react-i18next'
import {themeConfig, contactsURL} from '../theme'

const COMPANY_NUMBER = themeConfig.COMPANY_NUMBER

export const CookieStatement = () => {
    const {t} = useTranslation()

    return (
        <div>
            <h1>{t(`LegalPages.CookieStatement.Legal`)}</h1>
            <p>The GUP website uses cookies. By continuing to use this website, you are giving consent to cookies being
               used.</p>
            <h4>Last updated 25 March 2024</h4>
            <p>
                www.globalunitpay.ch is the website domain of GUP-CH AG. The data controller is GUP-CH AG, a financial
                intermediary registered with the Swiss Companies Register under company number{' '}
                <a
                    href={`https://zg.chregister.ch/cr-portal/auszug/auszug.xhtml?uid=${COMPANY_NUMBER}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {COMPANY_NUMBER}
                </a>
                , with its registered address at Chaltenbodenstrasse 16, 8834 Schindellegi, Switzerland. In this Cookie
                Statement, 'GUP', 'we', 'us' and 'our' means GUP-CH AG.
            </p>
            <p>
                This Cookie Statement explains how GUP uses cookies and similar technologies when you visit our websites
                located at www.globalunitpay.ch, or any other websites, pages, features, or
                content we own or operate (collectively, the "Site(s)"), when you use the GUP mobile app, and/or
                interact with GUP online advertisements or marketing emails (collectively the "Services").
                It explains what these technologies are and why we use them, as well as your rights to control our use
                of them.
            </p>

            <h3>What are cookies?</h3>
            <p>
                Cookies are small text files that are placed on your computer by websites that you visit. They are
                widely used in order to make websites work, or work more efficiently, as well as to
                provide information to the owners of the site.
            </p>
            <p>
                Cookies are small files, typically of letters and numbers, downloaded onto your computer or mobile
                device when you visit certain websites. When you return to these websites, or visit
                other websites that use the same cookies, the websites recognize these cookies and your browsing device.
                A cookie cannot read data off your hard drive or read cookie files created
                by other websites.
            </p>
            <p>
                Cookies set by the website operator are called "first party cookies". Cookies set by parties other than
                the website operator are called "third-party cookies". The parties that set
                third-party cookies can recognize your computer both when it visits the GUP website and when it visits
                certain other websites and/or mobile apps.
            </p>
            <p>
                We treat information collected by cookies and other technologies as non-personal information. However,
                to the extent that IP addresses or similar identifiers are considered personal
                information by local law, we also treat these identifiers as personal information. If we combine
                non-personal information with personal information, the combined information will
                be treated as personal information for as long as it remains combined.
            </p>
            <p>
                More information on cookies and their use can be found at{' '}
                <a
                    href="https://aboutcookies.org"
                    target="_blank"
                    rel="noreferrer"
                >
                    aboutcookies.org
                </a>{' '}
                or{' '}
                <a
                    href="https://allaboutcookies.org"
                    target="_blank"
                    rel="noreferrer"
                >
                    allaboutcookies.org
                </a>
                .
            </p>
            <p>
                We use third-party service provider(s), to assist us in better understanding the use of our website. Our
                service provider(s) will place cookies on the hard drive of your computer
                and will receive information that we select that will educate us on such things as how visitors navigate
                around our website, what products are browsed, and general Transaction
                information. Our service provider(s) analyses this information and provides us with aggregate reports.
                The information and analysis provided by our service provider(s) will be
                used to assist us in better understanding our visitors' interests in our website and how to better serve
                those interests. The information collected by our service provider(s)
                may be linked to and combined with information that we collect about you while you are using the
                Platform. Our service provider(s) is/are contractually restricted from using
                information they receive from our website other than to assist us.
            </p>

            <h3>How does GUP use cookies?</h3>
            <p>
                When you access our Services, we or the companies we work with may place cookies on your computer or
                other device. These technologies help us better understand user behavior
                and inform us about which parts of our websites people have visited.
            </p>
            <p>
                We use first-party and third-party cookies to recognize you as a GUP customer, customize our Services,
                content, and advertising, to measure promotional effectiveness, and to
                collect information about your computer or other access device to mitigate risk, help prevent fraud, and
                promote trust and safety.
            </p>
            <p>
                We may place cookies from third-party service providers who may use information about your visits to
                other websites to target advertisements for products and services available
                from GUP. We do not control the types of information collected and stored by these third-party cookies.
                You should check the third-party's website for more information on
                how they use cookies.
            </p>
            <p>The following are some examples of non-personal information that we collect and how we may use it:</p>
            <div className="dottedList">
                <p>
                    We may collect and store details of how you use our Services. Except in limited instances to ensure
                    the quality of our Services over the Internet, such information will
                    not be associated with your IP address.
                </p>
                <p>
                    We may collect information such as your language, zip code, area code, unique device identifier,
                    referrer URL, location, and time zone so that we can better understand customer
                    behavior and improve our Services.
                </p>
                <p>
                    We may collect information regarding customer activities on our website, which is used to understand
                    which parts of our websites and Services are of most interest. This data
                    is aggregated and thus is considered non-personal information for the purposes of this Cookie
                    Statement.
                </p>
            </div>

            <h3>What types of cookies does GUP use?</h3>
            <p>Strictly Necessary Cookies</p>
            <p>
                These cookies are essential because they enable you to move around our Services and use certain features
                on our Services. For example, strictly necessary cookies allow you to access
                secure areas. Without these cookies, some Services cannot be provided.
            </p>
            <p>Performance/Analytics Cookies</p>
            <p>
                These cookies collect information about how you use a website. For example, a performance/analytics
                cookie will collect information about which pages you go to most often, how much
                time you spend on that page, or if you get error messages from certain pages. These cookies do not
                gather information that identifies you. The information these cookies collect is
                anonymous and is only used to improve how our Services work.
            </p>
            <p>Functionality Cookies</p>
            <p>
                These cookies allow us to remember the choices you make and to tailor our Services so we can provide
                relevant content to you. For example, a functionality cookie can remember your
                preferences (e.g., country or language selection), or your username.
            </p>
            <p>
                Session Cookies. We use session cookies when you access our website or content. Session cookies expire
                and no longer have any effect when you log out of your account or
                close your browser.
            </p>

            <h3>How long will cookies stay on my browsing device?</h3>
            <p>
                The length of time a cookie will stay on your browsing device depends on whether it is a "persistent" or
                "session" cookie. Session cookies will only stay on your device until you
                stop browsing. Persistent cookies stay on your browsing device until they expire or are deleted (i.e.
                after you have finished browsing).
            </p>

            <h3>What other similar technologies does GUP use?</h3>
            <p>
                In addition to cookies, we may use other similar technologies, like web beacons to track users of our
                Services. Web beacons, or "clear gifs," are tiny graphics with a unique
                identifier, similar in function to cookies. They are used to track the online movements of web users.
            </p>
            <p>
                In contrast to cookies, which are stored on a user's computer hard drive or device, clear gifs are
                embedded invisibly on web pages and are about the size of the period at the end
                of this sentence. We and our third-party service provider employ web beacons for the reasons stated
                above (under "Cookies"), but primarily to help us better manage content on our
                Services by informing us which content is effective.
            </p>
            <p>
                We may also use so-called "Flash Cookies" (also known as "Local Shared Objects or "LSOs") to collect and
                store information about your use of our services, fraud prevention,
                and other site operations.
            </p>

            <h3>Does GUP use advertising cookies?</h3>
            <p>
                GUP may use third-party service providers to display advertising on our Services and serve advertising
                on other third-party sites that are more relevant to you or your interests.
                They are also used to limit the number of times you see an advertisement, as well as help measure the
                effectiveness of the advertising campaign. They remember that you have
                visited a website and this information may be shared with other organizations, such as advertisers. This
                means that after you have been to our websites, you may see some advertisements
                about our Services elsewhere on the Internet. The information collected through this process by the
                third-party service providers does not enable us or them to identify your name,
                contact details or other personal information that directly identifies you unless you choose to provide
                these. Such advertising will only be delivered where permitted by applicable law.
            </p>

            <h3>How to manage cookies, similar technologies, and targeted online mobile advertising, and what effect
                will this have?</h3>
            <p>
                You have the right to decide whether to accept cookies. You can stop cookies from being deployed on your
                computer by configuring your browser not to accept them or opt-out. Please
                refer to your browser's 'help' facility or visit the following website:{' '}
                <a
                    href="https://allaboutcookies.org"
                    target="_blank"
                    rel="noreferrer"
                >
                    allaboutcookies.org
                </a>
                . For more specific details, you can exercise your preferences in relation to cookies served on our
                website by taking the steps outlined below.
            </p>
            <p>
                Our Cookies (or, "First-Party Cookies"). You can use the browser with which you are viewing this website
                to enable, disable or delete cookies. To do this, follow the instructions
                provided by your browser (usually located within the "Help", "Tools" or "Edit" settings). Please note if
                you set your browser to disable cookies, you may not be able to access
                secure areas of our website. Also, if you disable cookies, other parts of the Services may not work
                properly. You can find more information about how to change your browser
                cookie settings at{' '}
                <a
                    href="https://allaboutcookies.org"
                    target="_blank"
                    rel="noreferrer"
                >
                    allaboutcookies.org
                </a>
                .
            </p>
            <p>
                Mobile Advertising: You can opt out of having your mobile advertising identifiers used for certain types
                of advertising by accessing the settings in your mobile device and following
                the instructions. If you opt-out, we will remove all data about you and will not collect any further
                data. The random ID previously assigned to you will be removed. Thus, if at a
                later stage, you decide to opt-in, we will be unable to continue and track you using the prior ID and
                you will for all practical purposes, be a new user.
            </p>
            <p>
                Third-Party Cookies. To opt-out of third-party advertising networks and similar entities that use
                targeting/advertising cookies, go to{' '}
                <a
                    href="https://optout.aboutads.info/?c=2&lang=EN"
                    target="_blank"
                    rel="noreferrer"
                >
                    aboutads.info/choices
                </a>
                . Once you click the link, you may choose to opt-out of such advertising from all participating
                advertising companies or only advertising provided by specific advertising entities.
                For more information about third-party advertising networks and similar entities that use these
                technologies, please see{' '}
                <a
                    href="https://youradchoices.com/control"
                    target="_blank"
                    rel="noreferrer"
                >
                    aboutads.info/consumers
                </a>
                .
            </p>
            <p>
                Do Not Track: Some Internet browsers - like Internet Explorer, Firefox, and Safari - include the ability
                to transmit "Do Not Track" or "DNT" signals. Since uniform standards for
                "DNT" signals have not been adopted, our websites may not currently process or respond to "DNT" signals.
            </p>
            <p>
                Flash Cookies. If you do not want Flash Cookies stored on your computer, you can adjust the settings of
                your Flash player to block Flash Cookies storage using the tools contained
                in the website storage settings panel. You can also control Flash Cookies by going to the global storage
                settings panel and following the instructions. Setting the Flash Player
                to restrict or limit acceptance of Flash Cookies may reduce or impede the functionality of some Flash
                applications, including, potentially, Flash applications used in connection
                with our services or online content.
            </p>

            <h3>Updates to this Cookie Statement</h3>
            <p>
                We reserve the right to amend or modify this Cookie Statement at any time in response to changes in
                applicable data protection and privacy legislation. If we do, you will be notified
                when you first visit our website after the change.
            </p>
            <p>Your continued use of the GUP website, as well as any subsequent usage, will be interpreted as your
               consent to cookies being stored on your device.</p>
            <p>
                If you have any questions about our use of cookies or other technologies, please email us using the
                contact form on the&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>
                &nbsp;page of our website.
            </p>
        </div>
    )
}
