import {useTranslation} from 'react-i18next'
import {contactsURL} from '../theme'
import {Link as RouterLink} from 'react-router-dom'

export const RiskWarning = () => {
    const {t} = useTranslation()

    return (
        <div>
            <h1>{t(`LegalPages.RiskWarning.Legal`)}</h1>
            <h4>Last updated 25 March 2024</h4>
            <p>Please read the following risk warnings carefully before using the GUP Service.</p>
            <p>
                This document provides you with information about the risks associated with using trading in
                cryptocurrencies and with using the GUP Service,
                which allows you to buy or sell interests in cryptocurrency via our partner cryptocurrency exchange(s)
                (each a "Cryptocurrency Exchange").
            </p>
            <p>
                The trading of goods and products, real or virtual, as well as virtual currencies, involves significant
                risk. Prices can and do fluctuate on any given day.
                Due to such price fluctuations, you may increase or lose value in your assets at any given moment. Any
                currency - virtual or not - may be subject to large
                swings in value and may even become worthless. There is an inherent risk that losses will occur as a
                result of buying, selling or trading anything on a market.
                If you are in any doubt about whether the Cryptocurrency Service is right for you, you may wish to seek
                guidance from a professional adviser.
            </p>
            <p>
                You should carefully assess whether your financial situation and tolerance for risk are suitable for any
                form of exposure to cryptocurrencies.
                You should never invest in cryptocurrencies unless you can afford to lose 100% of your investment.
            </p>

            <h3>Trading in Cryptocurrencies is unregulated</h3>
            <p>
                Trading cryptocurrencies such as Bitcoin has special risks not generally shared with official currencies
                or goods or commodities in a market.
                Unlike most currencies, which are backed by governments or other legal entities or by commodities such
                as gold or silver, cryptocurrencies are a unique
                kind of Internet digital currency backed by technology and trust. There is no central bank that can take
                corrective measures to protect the value of
                any cryptocurrency in a crisis or issue more currency.
            </p>
            <p>
                Instead, cryptocurrencies are an as-yet autonomous and largely unregulated global system of currency
                firms and individuals. Traders put their trust in a digital,
                decentralized, and partially anonymous system that relies on peer-to-peer networking and cryptography to
                maintain its integrity. As such, cryptocurrencies are
                not governed by any specific European or other regulatory framework. This means that when you use the
                GUP Service to purchase cryptocurrencies, you will not benefit
                from the protections available to customers receiving e-money/payment services provided by us. Moreover,
                GUP is not a bank and, as such, is not part of a depositor
                protection scheme. GUP is also not subject to direct FINMA supervision.
            </p>
            <p>
                We use our banking providers in order to receive client funds and vice versa. Our banking providers DO
                NOT transfer cryptocurrencies, exchange cryptocurrencies,
                or provide any services in connection with cryptocurrencies.
            </p>

            <h3>The value of Cryptocurrencies is highly volatile</h3>
            <p>
                The trading of cryptocurrencies is often susceptible to irrational bubbles or loss of confidence, which
                could collapse demand relative to supply. For example,
                confidence might collapse in cryptocurrencies because of unexpected changes imposed by software
                developers or others, a government crackdown, the creation of
                superior competing alternative currencies, or a deflationary or inflationary spiral. Confidence might
                also collapse because of technical problems: if the anonymity
                of the system is compromised, if money is lost or stolen, or if hackers or governments are able to
                prevent any transactions from settling.
            </p>
            <p>
                There may be additional risks that we have not foreseen or identified in our&nbsp;
                <RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink>.
            </p>
            <p>
                You should carefully assess whether your financial situation and risk tolerance are suitable for buying,
                selling, or trading cryptocurrencies. You should never trade
                in cryptocurrencies unless you can afford to lose 100% of your investment.
            </p>
            <p>
                There may be additional risks that we have not foreseen or identified in our&nbsp;
                <RouterLink to="/legal/terms-of-use">Terms of Use</RouterLink>. You should carefully assess
                whether your financial situation and tolerance for risk are suitable for buying, selling, or trading
                cryptocurrencies.
            </p>

            <h3>Cryptocurrency exchanges are vulnerable to cyber attacks</h3>
            <div className="dottedList">
                <p>
                    Cryptocurrency exchanges are entirely digital and, as with any virtual system, are at risk from
                    hackers, malware, and operational glitches. Even though
                    Cryptocurrency Exchanges take various steps to preserve the security of their platforms,
                    cryptocurrency, which is held in wallets provided by such exchanges,
                    remains vulnerable to hacking.
                </p>
                <p>
                    If a thief gains access to one or more Supported Cryptocurrencies (i.e. by stealing the private
                    encryption key to the Cryptocurrency Exchange wallets), he/she
                    could transfer the stolen assets to another account. This is particularly problematic since all
                    cryptocurrency transactions are permanent and irreversible.
                </p>
                <p>
                    Accordingly, a hack is likely to lead to a substantial depletion of the Supported Cryptocurrency
                    held on your behalf (see below). While the Cryptocurrency Exchange
                    may be willing to compensate users for any such loss, they are not obliged to do so. A serious hack
                    could also have the effect of putting a Cryptocurrency Exchange into insolvency.
                </p>
            </div>

            <h3>The supported cryptocurrencies (and the fiat currency used to buy/sell them) are held in custody by a
                third party.</h3>
            <div className="dottedList">
                <p>
                    When you use the GUP Service to buy interests in one or more of the Supported Cryptocurrencies, they
                    will be held on your behalf by a third party (usually the Exchange) who will
                    act as custodian (the "Custodian"). Records will be held by the Cryptocurrency Exchange and us to
                    show that the Supported Cryptocurrencies are held on your behalf and do not belong
                    to any third party.
                </p>
                <p>
                    The Supported Cryptocurrencies may be held on an "omnibus basis" with the Custodian, which means
                    that your interests may be held at a single blockchain address together with those
                    of other customers of that Custodian. If the Custodian becomes insolvent, there may be delays in
                    identifying the assets belonging to you. There may also be an increased risk of loss
                    if there should be a shortfall between the assets held in the Custodian's omnibus account and the
                    claims of all of its customers (in which case, you may have to share proportionately
                    in that shortfall with other customers).
                </p>
                <p>
                    Otherwise, in the event of insolvency of the Custodian, please note there is no specific legal
                    protection that covers you for losses arising from any Supported Cryptocurrencies that
                    were held with such Custodian. This could mean that you have no specific rights under insolvency law
                    to recover cryptocurrency held by the Custodian, and you may be treated as
                    an ordinary creditor of the same.
                </p>
                <p>
                    When you sell your interests in the Supported Cryptocurrencies, the proceeds from the sale will be
                    held in a pooled segregated bank account of the Cryptocurrency Exchange. As with
                    the Supported Cryptocurrencies, records will be held by the Cryptocurrency Exchange and us to show
                    that funds are held on your behalf and do not belong to any third party. In the
                    event of the Cryptocurrency Exchange's insolvency, you may be able to claim for the return of your
                    fiat currency from this pool.
                </p>
            </div>
            <h3>The Cryptocurrency Exchange may terminate their service at any time</h3>
            <div className="dottedList">
                <p>
                    To provide the GUP Service to you, we partner with one or more Cryptocurrency Exchanges to (a)
                    facilitate the buying and selling of interests in Supported Cryptocurrencies and (b),
                    in some cases, hold the Supported Cryptocurrencies as Custodian.
                </p>
                <p>
                    Given the various risks attached to cryptocurrency exchanges (including the uncertain legal
                    environment), it is possible that a Cryptocurrency Exchange could suspend or terminate
                    its relationship with us and may not tell us why. In these circumstances, we may have to suspend the
                    GUP Service ourselves, but we will try to transfer the underlying Supported
                    Cryptocurrency to another Cryptocurrency Exchange as soon as possible.
                </p>
            </div>

            <h3>The legal status of cryptocurrency is uncertain and evolving</h3>
            <div className="dottedList">
                <p>
                    Given that the market for cryptocurrencies is relatively new, the legal nature of cryptocurrency is,
                    in most jurisdictions, yet to be determined by statute, regulation, or case
                    law. In the absence of such authority, it is not clear how a regulator or court may treat interests
                    or rights arising from cryptocurrency trading. In particular, the law applicable
                    to firms who hold cryptocurrencies in custody (particularly in the event of such firms' insolvency)
                    is far from clear.
                </p>
                <p>
                    It is possible that a national or supranational regulator may take unilateral action to legislate
                    the cryptocurrency market in a manner that prevents or encumbers the proper operation
                    of the market in your jurisdiction. This may impact whether we can offer the Cryptocurrency Service
                    to you.
                </p>
            </div>

            <h3>The functioning of the cryptocurrency network is outside our control</h3>
            <div className="dottedList">
                <p>
                    Since the Blockchain is an independent public peer-to-peer network and is not subject to regulation
                    or control by any governmental or other authority or firm, we are not responsible
                    for any failure, mistake, error and/or breach which shall occur on the Blockchain or on any other
                    networks in which the Supported Cryptocurrencies are being issued and/or traded.
                </p>
                <p>
                    We do not own or control the underlying software protocols which govern the operation of the
                    Supported Cryptocurrencies. In general, the underlying protocols are open source, and
                    anyone can use, copy, modify, and distribute them.
                </p>
                <p>
                    The underlying protocols of the Supported Cryptocurrencies are subject to sudden changes in
                    operating rules ('forks'), and such forks may materially affect the value, function,
                    and/or even the name of the Supported Cryptocurrency.
                </p>
            </div>
            <h3>Changes and additions to this Risk Warning</h3>
            <p>
                Please check back often to review this&nbsp;<RouterLink to="#">Risk Warning</RouterLink>&nbsp;as we may
                amend it from time to time. If you have any questions regarding this Risk
                Statement, via the contact form on the&nbsp;<a
                href={contactsURL}
                target="_blank"
                rel="noreferrer"
            >Contact Us</a>&nbsp;page of our website.
            </p>
        </div>
    )
}
