import {useTranslation} from 'react-i18next'

export const AMLCTFPolicy = () => {
    const {t} = useTranslation()

    return (
        <div>
            <h1>{t(`LegalPages.AMLCTFPolicyComp.Legal`)}</h1>
            <p>
                Money laundering is defined as the process where the identity of the proceeds of crime are so disguised
                that it gives the appearance of legitimate income. Criminals specifically target
                financial services firms through which they attempt to launder criminal proceeds without the firm's
                knowledge or suspicions.
            </p>
            <p>
                In response to the scale and effect of money laundering, Switzerland has passed legislation designed to
                prevent money laundering and to combat terrorism. This legislation, together with
                regulations, rules and industry guidance, forms the cornerstone of AML/CTF obligations for Swiss firms
                and outlines the offenses and penalties for failing to comply.
            </p>
            <p>
                GUP-CH AG (GUP) is subject to provisions on Anti Money Laundering (AML) under the laws of Switzerland,
                which are in accordance with the FATF recommendations. Switzerland is a member country
                of the Financial Action Task Force FATF). This means, in particular, that GUP has to identify its
                customers and establish the beneficial owner's identity. The AML compliance policies approved
                by the Management Board include inter alia the processes for the identification of the customers and
                establishing the identity of the beneficial owner. The policies also cover the collection
                of information regarding the customers' business activities, relationships with Politically Exposed
                Persons, and record retention procedures. Furthermore, GUP regularly provides AML training
                to relevant employees and does not provide banking services to any bank that does not maintain a
                physical presence in any country and is not a regulated affiliate. The anti-money laundering
                policies are applicable to head office and branches alike and are in accordance with the Wolfsberg
                anti-money laundering principles.
            </p>

            <h3>The fight against money laundering and terrorist financing</h3>
            <p>
                As a supervisory body approved by FINMA, d'Organisme de Surveillance pour Intermédiaires Financiers &
                Trustees (SOFIT) ensures the respect by its members of the Swiss Federal Act on Combating
                Money Laundering and Terrorist Financing in the Financial Sector (MLA) in the following areas.
            </p>
            <div className="dottedList">
                <p>asset and fund management</p>
                <p>exchange and money transfer</p>
                <p>company administration trusts</p>
                <p>payment services</p>
                <p>credit, leasing and factoring</p>
                <p>lawyers and notaries</p>
                <p>insurance brokerage</p>
            </div>

            <h3>Anti-Money Laundering (AML) Policy:</h3>
            <p>
                The GUP AML Policy is designed to prevent money laundering by meeting the Swiss AML legislation
                obligations, including the need to have adequate systems and controls in place to mitigate the
                risk of the firm being used to facilitate financial crime. This AML Policy sets out the minimum
                standards which must be complied with and includes:
            </p>
            <div className="dottedList">
                <p>
                    The appointment of a Money Laundering Reporting Officer (MLRO) who has a sufficient level of
                    seniority and independence and who has responsibility for oversight of compliance with relevant
                    legislation, regulations, rules, and industry guidance;
                </p>
                <p>Establishing and maintaining a Risk Based Approach (RBA) towards assessing and managing the money
                   laundering and terrorist financing risks to the company;</p>
                <p>
                    Establishing and maintaining risk-based customer due diligence, identification, verification, and
                    know-your-customer (KYC) procedures, including enhanced due diligence for those customers
                    presenting higher risk, such as Politically Exposed Persons (PEPs);
                </p>
                <p>Establishing and maintaining risk-based systems and procedures to monitor ongoing customer
                   activity;</p>
                <p>Procedures for reporting suspicious activity internally and to the relevant law enforcement
                   authorities as appropriate;</p>
                <p>The maintenance of appropriate records for the minimum prescribed periods;</p>
                <p>Training and awareness for all relevant employees</p>
            </div>

            <h3>Sanctions Policy:</h3>
            <p>
                GUP is prohibited from transacting with individuals, companies, and countries that are on prescribed
                Sanctions lists. GUP will, therefore, screen against the relevant sanctions lists in the
                jurisdictions in which we operate.
            </p>

            <h3>Transaction monitoring Virtual Assets (Travel Rule)</h3>
            <p>
                In- and outflows in Virtual Assets performed from or to an external wallet are permitted if the customer
                is identical to the person controlling the external wallet by having access to the wallet.
                GUP verifies this requirement by using technical means as follows:
            </p>
            <div className="dottedList">
                <p>Providing an external wallet to the credentials presented by the customer during the onboarding
                   process or;</p>
                <p>Obtaining a print screen of the external wallet or;</p>
                <p>Verifying access of the customer to the external wallet presented by a transfer of a small amount
                   (so-called Satoshi test) and getting proof of receive by the customer or;</p>
                <p>Verifying access of the customer to the external wallet presented by sending a message (such as a
                   password) to the wallet of the customer and getting proof of receive by the customer or;</p>
                <p>Obtaining a digital signature verification for both single and multi-signature (MultiSig)
                   wallets.</p>
            </div>
            <p>After successful proof of control, the wallet is assigned to the customers' profile and can be used for
               in- and out-going payments in Virtual Assets.</p>
            <p>
                If an incoming transaction is not originating from a verified wallet of the customer, proof of control
                must be provided immediately. Otherwise, GUP initiates an investigation
                for suspicious transactions.
            </p>
            <p>
                In case the customer uses an external wallet hosted by a third party, the provider of hosted wallets
                submits the name, account number, and address of the respective wallet holder as
                well as the name and account number of the beneficial owner so that GUP is able to provide full
                identification.
            </p>
        </div>
    )
}
